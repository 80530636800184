export const checkAttribute = (random1) => {
    //C：100，O：200，N：100，F：100，L：100，U：50，X：100
    //0~100,100~300,300~400,400~500,500~600,600~650,650~750
    if(random1>=0 && random1<100){
        return 1;
    }else if (random1>=100 && random1<300){
        return 2;
    }else if (random1>=300 && random1<400){
        return 3;
    }else if (random1>=400 && random1<500){
        return 4;
    }else if (random1>=500 && random1<600){
        return 5;
    }else if (random1>=600 && random1<650){
        return 6;
    }else if (random1>=650 && random1<750){
        return 7;
    }
}

export const getCardPicUrl = (level) => {
    return "http://cj.yzbbanban.com/"+level+".gif"
}

export const getRewardCardUrl = () => {
    return "http://cj.yzbbanban.com/10000.gif"
}



export const getLevelUrl = (level) => {
    return "http://cj.yzbbanban.com/"+checkAttribute(level)+".gif"
}

export const getLevelName = (level) => {
    let le = checkAttribute(level)
    switch (le) {
        case 1:
            return "C";
        case 2:
            return "O";
        case 3:
            return "N";
        case 4:
            return "F";
        case 5:
            return "L";
        case 6:
            return "U";
        case 7:
            return "X";
        default:
            return "";
    }
}