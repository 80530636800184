import { getContract } from "@/util/base.js";
import CfxReward from "@/abi/CfxReward.json";

let contract
//正式网
// let contractAddress='0x8bf097cc1d4d7b6d4da0a42b3224bf67e1661ce4'
let contractAddress='cfx:acf9bf8pdzg105mrycwc0pve17x8c3u66ubpdpszpj'

//测试网
// let contractAddress='0x876143e34f607496d3fcb91db51bddd638dca6af'

/**
 * 全局init(名称使用合约名命名)
 */
export const initCfxRewardContract = async()=>{
    if(contract == null){
        contract = await getContract(contractAddress,CfxReward);
        console.log(contract)
    }
}

  
/**
 * 获取地址
 */
export const getCfxMinerContractAddress = ()=>{
    return contractAddress
}
  
/**
 * 创建合约调用
 */
export const getCfxMinerContract = ()=>{
    return contract
}

export const tokenHasExchange = async (tokenId) => {
    return await contract.tokenHasExchange(tokenId).call();
}

export const rewardCount = async () => {
    return await contract.rewardCount().call();
}

/**
 * 兑换 cfx
 * @param {address} account 执行人
 * @param {Number} _tokenId _tokenId
 */
export const exchangeCfxReward = async(account,_tokenId) => {
    let collateral = await contract.exchangeCfxReward(_tokenId).estimateGasAndCollateral({from:account});
    return await contract.exchangeCfxReward(_tokenId).sendTransaction({from:account,gas:collateral.gasLimit,storageLimit:collateral.storageCollateralized}).executed()
}
