export const get1155 = (type1155) =>{
    if(type1155==1){
        //card
        //正式网
        return "cfx:accczxbt4ebbnns39k1zczgj4s397h89mjheyw8gt7";
        // return "0x842acc2fd10215add9fa6f5154c8d3b3fe9fdf52";
        //测试网
        // return "0x8a31017f58169a81a16d4610bdc5f848856778c8";
    }else if(type1155==2){
        //reward
        //正式网
        return "cfx:acerdrbgc87wvrbfvu18sgwtdbw79wsaha851ues9m";
        // return "0x88d1b42617bb28b4258c2fe71a4f1865dfc9c038";
        //测试网
        // return "0x8867a8fcc89926ac810ad96d384a7416d5347e4f";
    }else{
        return "";
    }
}

export const changeToOldAddress = () => {

}