import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
// import router from './router'
import Vant from "vant";
import "vant/lib/index.css";
import "./assets/styles/bass.css";
import { Lazyload } from "vant";
import { Notify } from 'vant';
import { Dialog } from 'vant';

Vue.use(Notify);
Vue.use(Vant);
Vue.use(Dialog);
Vue.use(Lazyload);
new Vue({
  render: (h) => h(App),
}).$mount("#app");
