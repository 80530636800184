<template>
  <div class="container">
    <div class="header">
      <div class="con-address" @click="getAddress">{{address}}</div>
      <div class="tabs">
        <div v-for="item in 3" :key="item" :class="{ active: item === active }" class="tab" @click="active = item"></div>
      </div>
    </div>
    <div class="main">
      <Games v-if="active == 1"></Games>
      <Shop v-if="active == 2"></Shop>
      <Mine v-if="active == 3"></Mine>
    </div>
    <div class="footer">
      <a style="margin-top:20px" href="https://portal.conflux-chain.org/" target="_blank">钱包指导</a>
    </div>
  </div>
</template>

<script>
import { getAddress } from '../util/base.js'
import { Toast } from 'vant'
import Games from './Games'
import Shop from './Shop'
import Mine from './Mine'
export default {
  name: 'Home',
  components: {
    Games,
    Shop,
    Mine
  },
  data() {
    return {
      active: 1,
      address: '连接钱包'
    }
  },
  async created() {
    this.getAddress();
  },
  methods: {
    async getAddress() {
      let res = '' + (await getAddress())
      localStorage.setItem('MyAccount', res);
      console.log('===========')
      this.address = res.substring(0, 6) + '...' + res.substring(res.length - 4, res.length)
    },
    onClickLeft() {
      Toast('返回')
    },
    onClickRight() {
      Toast('连接钱包')
      this.getAddress()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.con-address{
  cursor:pointer; 
  text-align: right;
  padding-right: 10px;
  padding-bottom: 5px;
  color: aliceblue;
  font-weight: bolder;
}
.container {
  height: 100vh;
  padding: 90px 0 70px;
  background-image: url('../assets/images/bg-top.png'), url('../assets/images/bg-main.png');
  background-repeat: no-repeat;
  background-position: top left, top center, bottom left;
  background-size: 100% 78px, 100% 100%;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 24px;
  z-index: 100;
}
.header .tabs {
  display: flex;
  justify-content: space-around;
}
.header .tabs .tab {
  width: 110px;
  height: 58px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.header .tabs .tab:first-of-type {
  background-image: url('../assets/images/tab-exchange.png');
}
.header .tabs .tab.active:first-of-type {
  background-image: url('../assets/images/tab-exchange-s.png');
}
.header .tabs .tab:nth-of-type(2) {
  background-image: url('../assets/images/tab-market.png');
}
.header .tabs .tab.active:nth-of-type(2) {
  background-image: url('../assets/images/tab-market-s.png');
}
.header .tabs .tab:last-of-type {
  background-image: url('../assets/images/tab-mine.png');
}
.header .tabs .tab.tab.active:last-of-type {
  background-image: url('../assets/images/tab-mine-s.png');
}
.main {
  height: 100%;
  overflow-y: auto;
}
.footer {
  padding-top: 45px;
  padding-left: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 70px;
  background: url('../assets/images/bg-bottom.png') no-repeat center;
  background-size: cover;
}
@media screen and (min-width:768px) {
  .container,.header,.footer{
    width: 420px;
    margin: auto;
  } 
}
</style>
