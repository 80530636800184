import { getContract } from "@/util/base.js";
import CfxMiner from "@/abi/CfxMiner.json";

let contract
//正式网
// let contractAddress='CFX:TYPE.CONTRACT:ACH20WE1BYYUS49UB7EYPN9R72N2TZSY22X0CCCSNA'
// let contractAddress='0x8f8b48970d29076bf00f49462fedee1787d5d4c6'
let contractAddress='cfx:ach20we1byyus49ub7eypn9r72n2tzsy22x0cccsna'

//测试网
// let contractAddress='0x8a1498f907cfc73a1751ec52dd1aead85e029955'
// let contractAddress='0x8db02003cf74160edd2c98571678b093f94b9a16'

/**
 * 全局init(名称使用合约名命名)
 */
export const initCfxMinerContract = async()=>{
    if(contract == null){
        contract = await getContract(contractAddress,CfxMiner);
        console.log(contract)
    }
}

  
/**
 * 获取地址
 */
export const getCfxMinerContractAddress = ()=>{
    return contractAddress
}
  
/**
 * 创建合约调用
 */
export const getCfxMinerContract = ()=>{
    return contract
}

/**
 * 获取当前基本信息
 */
export const getBaseInfo = async (account) => {
    return await contract.getBaseInfo(account);
}

export const getIsReset = async (address, lastExchangeTime) => {
    return await contract.isReset(address,lastExchangeTime);
}

/**
 * 获取总得兑换量
 */
export const balance = async () => {
    return await contract.balance();
}

/**
 * 获取上次更新时间
 */
export const lastExchangeTime = async () => {
    return await contract.lastExchangeTime();
}
/**
 * 获取结束时间
 */
export const endTime = async () => {
    return await contract.endTime();
}
/**
 * 获取当前剩余量
 */
export const addressCount = async (account) => {
    return await contract.addressCount(account);
}
/**
 * 获取每天的剩余量
 */
export const dayCountRest = async () => {
    return await contract.dayCountRest();
}

/**
 * 获取总共的剩余量
 */
export const totalCountRest = async () => {
    return await contract.totalCountRest();
}

/**
 * 获取最小值
 */
export const minNum = async () => {
    return await contract.totalCountRest();
}
/**
 * 获取最大值
 */
export const maxNum = async () => {
    return await contract.totalCountRest();
}

/**
 * 猜数字
 * @param {address} account 执行人
 * @param {number} _word 数字
 */
export const exchangeWithWord = async(account,_word) => {
    let collateral = await contract.exchangeWithWord(_word).estimateGasAndCollateral({from:account});
    return await contract.exchangeWithWord(_word).sendTransaction({from:account,gas:collateral.gasLimit,storageLimit:collateral.storageCollateralized}).executed()
}

/**
 * 兑换 conflux
 * @param {address} account 执行人
 * @param {Array} _tokenIds tokenIds
 */
export const exchangeRewardCard = async(account,_tokenIds) => {
    let collateral = await contract.exchangeRewardCard(_tokenIds).estimateGasAndCollateral({from:account});
    return await contract.exchangeRewardCard(_tokenIds).sendTransaction({from:account,gas:collateral.gasLimit,storageLimit:collateral.storageCollateralized}).executed()
}

/**
 * 兑换 cfx
 * @param {address} account 执行人
 * @param {Number} _tokenId _tokenId
 */
export const exchangeCfxReward = async(account,_tokenId) => {
    let collateral = await contract.exchangeCfxReward(_tokenId).estimateGasAndCollateral({from:account});
    return await contract.exchangeCfxReward(_tokenId).sendTransaction({from:account,gas:collateral.gasLimit,storageLimit:collateral.storageCollateralized}).executed()
}
