<template>
      <div class="mine">
        <van-pull-refresh style="min-height: 100vh;" v-model="isLoading" @refresh="onRefresh">
          <van-tabs v-model="active" :before-change="beforeChange">
            <van-tab title="我的字母"></van-tab>
            <van-tab title="我的奖励"></van-tab>
          </van-tabs>
          <div class="list-content">
            <div class="card" v-for="(item, value) in list" :key="value">
              <div class="card-check">
                <van-checkbox v-model="item.check" icon-size="16px" checked-color="#f0cc72"/>
              </div>
              <van-image width="120" height="180" lazy-load :src="item.imgUrl" @click="sell(item)">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <van-tag v-if="item.level=='6'" style="position:absolute; left:50px; top:180px;" type="primary">稀有</van-tag>
              <div style="float:left;margin-right:45px">
                <div class="card-div">{{ item.tokenId }}</div>
                <div class="card-div">名称: {{ item.name }}</div>
              </div>
              <div style="margin-top:10px">
                <van-button size="mini" color="#ee0a24" icon="exchange" type="primary" @click="exchange(item)"/>
              </div>
            </div>
          </div>
          <div class="van-dialog1" v-show="show">
              <div style="margin-top:90%" @click="dismiss">
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/1.gif" />
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/2.gif" />
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/3.gif" />
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/4.gif" />
                <br>
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/5.gif" />
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/6.gif" />
                <van-image class="van-i" width="70px" src="http://cj.yzbbanban.com/7.gif" />
                <br>
                <van-button style="width:150px" round type="info" @click="composeEn">确认合成</van-button>
              </div>
          </div>
          <div class="van-dialog1" v-show="priceShow">
            <div style="margin-top:80%">
              <input class="w" v-model="gprice" type="text" placeholder="输入价格" name="textfield" id="textfield" />
            </div>
            <div>
              <van-button style="width:100px" round type="info" @click="cancle()">取消</van-button>
              <van-button style="width:100px" round type="info" @click="submitPrice()">确认</van-button>
            </div>
          </div>
          <div class="van-dialog2" v-show="transferDialog">
            <div class="dialog-div2">
              <input class="wp" v-model="toAddress" type="text" placeholder="输入地址" name="textfield" id="textfield" />
              <br>
              <van-button style="width:100px" round type="info" @click="cancleTransfer()">取消</van-button>
              <van-button style="width:100px" round type="info" @click="submitTransfer()">确认</van-button>
            </div>
          </div>
          <div class="van-dialog2" v-show="rewardDialog">
            <div class="dialog-div">
              <h2 v-if="!hasEx" style="margin-top:50%">
                💐您可以领取奖励啦💐
              </h2>
              <h2 v-else style="margin-top:50%">
                💐您已经领取过啦，请去余额查看💐
              </h2>
              <div>奖励金额为：{{rewardBalance}}</div>
              <van-button style="width:100px;margin-top:20px" round type="info" @click="rewardDialog=false">取消</van-button>
              <van-button v-show="!hasEx" style="width:100px" round type="info" @click="exchangeCR()">领取</van-button>
              <div>💐💐💐💐💐💐💐💐💐💐💐💐💐💐💐</div>
              <div>💐💐💐💐 期待下次见面 💐💐💐💐</div>
              <div>💐💐💐💐💐💐💐💐💐💐💐💐💐💐💐</div>
              <div>💐💐💐💐💐好玩委员会💐💐💐💐💐</div>
              <div>💐💐💐💐💐💐💐💐💐💐💐💐💐💐💐</div>
              <div>------ yzbbanban@live.com</div>
            </div>
          </div>
        </van-pull-refresh>
        <!-- <van-button class="van-position" round type="info" native-type="submit" @click="composeFilter">合成</van-button> -->
      </div>
</template>

<script>
import { Toast } from 'vant';
import {checkAttribute,getLevelUrl,getLevelName,getRewardCardUrl} from '../util/getLevel.js';
import {tokensOf,safeTransferFrom,setApprovalForAll,isApprovedForAll,init1155Contract} from '../util/index1155.js';
import {initConfluxShopContract,getConfluxShopContractAddress,sellTicket} from "../util/indexConfluxShop.js"
import {initCfxMinerContract,exchangeRewardCard,
getCfxMinerContractAddress,endTime} from "../util/indexCfxMiner.js"
import {initCfxRewardContract,exchangeCfxReward,tokenHasExchange,rewardCount} from "../util/indexCfxReward.js"
import {decimalToBalance} from "../util/MathUtil.js";
import {get1155, changeToOldAddress} from "../util/contract.js";

import { Dialog } from 'vant';
import { Notify } from 'vant';

export default {
  name: 'Mine',
  async created() {
    this.address = '' + localStorage.getItem('MyAccount');
    //card CONFLUX
    await init1155Contract(get1155(1));
    await initCfxMinerContract();
    await initCfxRewardContract();
    await initConfluxShopContract();
    this.initList(this.active)
    changeToOldAddress()
  },
  data() {
    return {
      rewardCount:0,
      rewardBalance:"0",
      rewardDialog:false,
      noData:false,
      active:0,
      arrTokens:[],
      isLoading: false,
      transferDialog:false,
      tokenId:"",
      toAddress:"",
      wordPrice:"0.00",
      show: false,
      gprice:"",
      priceShow: false,
      address: '',
      sellForm:{},
      hasEx:false,
      list: [
      ],
    }
  },
  methods: {
    async exchangeCR(){
      try {
        console.log('===>'+this.tokenId)
        let res = await exchangeCfxReward(this.address, this.tokenId);
        console.log('exchangeCR')
        console.log(`${res}`)
        Toast('❀❀❀❀领取成功❀❀❀❀')
      } catch (error) {
        console.log(error)
        Toast('取消操作')
      }
      this.rewardDialog = false;

    },
    beforeChange(index) {
      this.initList(index)
      this.active = index
      // // 返回 Promise 来执行异步逻辑
      // return new Promise((resolve) => {
      //   // 在 resolve 函数中返回 true 或 false
      //   resolve(index !== 3);
      // });
    },
    onRefresh() {
      this.initList(this.active)
      // setTimeout(() => {
      //   Toast('刷新成功');
      // }, 1000);
    },
    async initList(myType){
      if(myType==0){
        await init1155Contract(get1155(1));
      }else{
        await init1155Contract(get1155(2));
      }
      this.list=[]
      let res = await tokensOf(this.address);
      console.log(`initList: ${res}`)
      let arr = JSON.parse(JSON.stringify(res));
      if(arr.length==0){
        Toast("没有数据")
        this.noData = true;
        return
      }else{
        this.noData = false;
      }
      for (const key in arr) {
        if (Object.hasOwnProperty.call(arr, key)) {
          const nft = arr[key];
          let nftDetail
          if(myType!=0){
            nftDetail = { tokenId: nft,level:'0', check:false,name: "🎉",imgUrl: getRewardCardUrl(nft) }  
          }else{
            nftDetail = { tokenId: nft,level:checkAttribute(nft), check:false,name: getLevelName(nft),imgUrl: getLevelUrl(nft) }
          }
          if(!this.noData){
            this.list.push(nftDetail)
          } 
        }
      }
      if(this.noData){
        this.list = []
      }
      console.log('init: ' + this.list)
      this.isLoading = false;
      await init1155Contract(get1155(1));
    },
    async sell(item){
        this.tokenId = item.tokenId
        let time = new Date()/1000;
        let et = await endTime();
        console.log(time)
        console.log(et)
        console.log(item.level)
        if(item.level=='0' ){
          if(time < Number(et)){
            Toast('还未到开奖日期哦🎁')
          }else{
            this.rewardDialog = true;
            this.rewardCount = await rewardCount();
            this.rewardBalance = Number(2021 / Number(this.rewardCount)).toFixed(4)
            let hasExchange = await tokenHasExchange(this.tokenId);
            console.log('hasexchange: '+hasExchange)
            if((''+hasExchange)!='false'){
              console.log('===s=s=')
              this.hasEx = true
              Toast('您已经领取过'
              +'💐💐💐💐💐💐💐💐💐💐💐💐💐💐💐'
              +'💐💐💐💐 期待下次见面 💐💐💐💐'
              +'💐💐💐💐💐💐💐💐💐💐💐💐💐💐💐'
              +'💐💐💐💐💐好玩委员会💐💐💐💐💐'
              +'💐💐💐💐💐💐💐💐💐💐💐💐💐💐💐'
              +'------ yzbbanban@live.com'
              )
            }else{
              this.hasEx = false
            }
          }
          return
        }
        //先授权
        let operator = getConfluxShopContractAddress();
        let approved = await isApprovedForAll(this.address,operator);
        var that = this;
        if(!approved){
          console.log('未授权')
          Dialog.confirm({
            title: '提示',
            message: '需要授权, 是否继续?',
          })
          .then(() => {
            // on confirm
            setApprovalForAll(operator,that.address).then(tx=>{
              console.log(tx)
              Notify('授权成功');
            })
          })
          .catch(() => {
            // on cancel
             Notify('授权失败');
          });
        }else{
          console.log('已授权')
          console.log(approved)
          console.log(item.tokenId)
          this.sellForm={
            price:0,
            from: this.address,
            tokenId:item.tokenId
          }
          this.priceShow = true
        }
    },
    async sellToShop(){
        const toast=Toast.loading({
          message: '提交中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0,
        });
        let pr=this.gprice;
        try {
           let tx = await sellTicket(this.address,this.sellForm.tokenId,decimalToBalance(pr));
            console.log(tx);
            this.$notify({
              title: '成功',
              message: '售卖上架成功',
              type: 'success'
            });
            toast.clear()
        } catch (error) {
          toast.clear()
          Toast.setDefaultOptions({ duration: 3000 });
          Toast('取消操作')
        }
       
        this.priceShow = false
        this.initList(this.active)
    },
    async setApprovalForAll(){
      let res = await setApprovalForAll();
      console.log(`setApprovalForAll: ${res}`)
    },
    cancleTransfer(){
      this.transferDialog = false
    },
    async submitTransfer(){
      const toast=Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
      });
      try{
        if(this.active==0){
          await init1155Contract(get1155(1));
        }else{
          await init1155Contract(get1155(2));
        }
        console.log('=====>'+this.active)
        let res = await safeTransferFrom(this.address,this.toAddress,this.tokenId);
        console.log(`transfer: ${res}`)
        toast.clear()
        this.$message({
              type: 'info',
              message: '发送成功'
            });   
      }catch(error){
        console.log('==> '+error)
        toast.clear()
        Toast.setDefaultOptions({ duration: 3000 });
        Toast('取消操作')
      }
      this.transferDialog = false
      this.initList(this.active)
    },
    exchange(item){
      this.tokenId = item.tokenId
      this.transferDialog = true;
    },
    cancle(){
      this.priceShow = false
    },
    submitPrice(){
      this.priceShow = false
      this.sellToShop()
    },
    dismiss(){
      this.show = false
    },
    async compose(){
      if(this.arrTokens.length==0){
        Toast("未选择卡片")
        return
      }
      let op = getCfxMinerContractAddress();
      try {
        let approved = await isApprovedForAll(this.address,op);
        var that = this;
        if(!approved){
          console.log('未授权')
          Dialog.confirm({
            title: '提示',
            message: '需要授权, 是否继续?',
          })
          .then(() => {
            // on confirm
            try{
              setApprovalForAll(op,that.address).then(tx=>{
                console.log(tx)
                Notify('授权成功');
              })
            } catch (error) {
            console.log('====>'+error)
            Notify('点击地址连接钱包');
            }
          })
          .catch(() => {
            // on cancel
            Notify('授权失败');
          });
        }else{
          console.log('已授权')
          console.log(approved)
          this.show = true
        }
      } catch (error) {
        console.log('====>'+error)
        Notify('点击地址连接钱包');
      }
      
    },
    async composeEn(){
      const toast=Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
      });
      try {
        let res = await exchangeRewardCard(this.address,this.arrTokens);
        console.log(`transfer: ${res}`)
        toast.clear()
        Notify('兑换成功');
      } catch (error) {
        console.log(error)
        toast.clear()
        Toast.setDefaultOptions({ duration: 3000 });
        Toast('取消操作')
      }
      this.show = false
    },
    composeFilter(){
      this.compose();
      this.arrTokens = []
      let arr = []
      for (const key in this.list) {
        const element = this.list[key];
        if(element.check){
          arr.push(element)
        }
      }
      console.log(JSON.stringify(arr))
      if(arr.length==7){
        //check level
        let num=0;
        for (const key2 in arr) {
          const e2 = arr[key2];
          num = num + Number(checkAttribute(e2.tokenId))**2;
          this.arrTokens.push(''+e2.tokenId);
        }
        console.log(num)
        if(num==140){
          this.show = true
        }else{
          Toast('请选择字母C、O、N、F、L、U、X')
          this.arrTokens = []
          return
        }
      }else{
        Toast('请选择字母C、O、N、F、L、U、X')
        this.arrTokens = []
        return
      }
    },
    getDetial(item){
      console.log(item.tokenId)
      this.priceShow = true
    }
  }
}
</script>

<style scoped>
.van-position{
  bottom: 48px;
  z-index: 201;
  position:fixed;
}
.mine {
  height: 100%;
  margin-top: 16px;
  padding: 0 10px;
}
.list-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card {
  position:relative;
  width: 175px;
  height: 240px;
  padding: 15px 28px 35px;
  margin-bottom: 16px;
  background-image: url(../assets/images/minebac.png);
  background-repeat: round;
  border-radius: 10px;
  /* text-align: center; */
  color: #645034;
  font-size: 14px;
}
.card-check{
  width: 100%;
  margin-left: -10px;
  margin-top: -15px;
  z-index: 100;
  position: relative;
  overflow: hidden;
}
.card-div {
  /* text-align: center; */
  /* margin-left: 20px; */
  font-weight: bold;
}

.create-button {
  float: left;
  font-size: 20px;
  margin-left: 50px;
}

.w {
  width: 200px;
  height: 200px;
  font-size: 25px;
  color: #f6e1a8;
  border: 1px solid #ffffff00;
  background-image: url(../assets/images/guessnum.png);
  background-repeat: round;
  background-color: #ffffff00;
  text-align: center;
}
.wp {
  margin-top: 50%;
  padding-left: 15px;
  padding-right: 15px;
  width: 70%;
  height: 100px;
  font-size: 20px;
  color: #f6e1a8;
  border: 1px solid #ffffff00;
  background-image: url(../assets/images/shop-bg.png);
  background-repeat: round;
  background-color: #ffffff00;
  text-align: center;
}
.dialog-div{
  text-align:center;
  display:table-cell;
  vertical-align:middle; 
}
.dialog-div2{
  display:table-cell;
  text-align:center;
  vertical-align:middle; 
}
</style>
