<template>
    <van-pull-refresh style="min-height: 100vh" v-model="isLoading" @refresh="onRefresh">
      <div class="shop">
        <van-tabs v-model="active" :before-change="beforeChange">
          <van-tab title="商场"></van-tab>
          <van-tab title="我的"></van-tab>
        </van-tabs>
        <van-search
          class="van-s"
          v-model="value"
          placeholder="请输入字母"
          @search="onSearch"
        />
        <div class="card" v-for="(item, value) in tempPriList" :key="value" @click="showDetail(item)">
          <van-image width="120" height="170" :src="item.imgUrl" lazy-load>
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="right-box">
            <div class="name">{{ item.tokenId }}</div>
            <div class="name">卖家: {{ item.sellershot }}</div>
            <div class="desc">名称: {{ item.name }}</div>
            <div v-if="item.level=='6'" class="desc2">稀有</div>
            <div class="bottom">
              <div><span class="num">{{item.price}}</span></div>
            </div>
          </div>
        </div>
        <div class="van-dialog2" v-show="show">
          <div class="dialog-div">
            <img width="150" :src="showImage" @click="dialogMis"/>
            <div class="dialog-price">{{shellPrice}}</div>
            <van-button v-if="address!=seller" style="width:100px" round type="info" @click="buy">购买</van-button>
            <van-button v-else style="width:100px" round type="info" @click="cancel">撤回</van-button>
          </div>
        </div>
      </div>
    </van-pull-refresh>
</template>

<script>
import {initConfluxShopContract,getShopItems,getSellerShop,
  buySellerTicket,shopMap,cancelShell} from "../util/indexConfluxShop.js"
import {decimalToBalance,balanceToDecimal} from "../util/MathUtil.js";
import {getLevelUrl,getLevelName,checkAttribute} from '../util/getLevel.js';
import { Toast } from 'vant';
import {format} from 'js-conflux-sdk/';
export default {
  name: 'Shop',
  async created() {
    this.address = '' + localStorage.getItem('MyAccount')
    this.chainId = window.conflux.chainId
    await initConfluxShopContract();
    this.getShopList(this.active);
  },
  data() {
    return {
      chainId:"",
      noData:false,
      value: '',
      active:0,
      tokenId:'',
      isLoading: false,
      show:false,
      tempPriList:[],
      seller:'',
      shellPrice:"",
      showImage:'',
      priList: [
      ]
    }
  },
  methods: {
    onSearch(val) {
      if(val==""){
        this.tempPriList = this.priList
        return
      }
      Toast('搜索：'+val)
      let keyword = val.toUpperCase()
      // console.log(keyword)
      this.tempPriList=[]
      // console.log(this.priList)
      for (let index = 0; index < this.priList.length; index++) {
        const element = this.priList[index];
        // console.log(element)
        if(element.name==keyword){
          this.tempPriList.push(element)
        }
      }
      // console.log(this.tempPriList)
      if(this.tempPriList.length==0){
        Toast('没有搜索结果')
      }
    },
    beforeChange(index) {
      this.getShopList(index)
      // console.log(index)
      this.active = index
      // // 返回 Promise 来执行异步逻辑
      // return new Promise((resolve) => {
      //   // 在 resolve 函数中返回 true 或 false
      //   resolve(index !== 3);
      // });
    },
    onRefresh() {
      this.getShopList(this.active)
    },
    async getShopList(shopType){
      let rlist;
      if(shopType==0){
        rlist=await getShopItems();
      }else{
        rlist=await getSellerShop(this.address);
      }
      // console.log(rlist)
      this.priList=[]
      this.tempPriList=[]
      if(rlist.length==0){
        Toast("没有数据")
        this.noData = true;
        return
      }else{
        this.noData = false;
      }
      for (const key in rlist) {
        let tokenId=rlist[key][0];
        let shopInfo = await shopMap(tokenId);
        let shopArr = JSON.parse(JSON.stringify(shopInfo))
        console.log(shopArr[0])
        console.log(format.address(''+shopArr[0],Number(this.chainId)))
        let addr = format.address(''+shopArr[0],Number(this.chainId))
        let detail = {tokenId,name:getLevelName(tokenId),imgUrl: getLevelUrl(tokenId),
                    seller:addr,level:checkAttribute(tokenId),
                    sellershot:addr.substring(0,6)+'...'+addr.substring(addr.length - 4, addr.length),
                    price:balanceToDecimal(shopArr[1])
                    }
        if(!this.noData){
          this.priList.push(detail)
          this.tempPriList.push(detail)
        }                   
      }
      if(this.noData){
        this.priList = []
        this.tempPriList = []
      }
      // console.log(JSON.stringify(this.priList))
      this.isLoading = false;
    },
    async cancel(){
      const toast=Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
      });
      try{
        // console.log(this.address)
        // console.log('tokenId===> '+this.tokenId)
        await cancelShell(this.address,this.tokenId)
        toast.clear()
      } catch (error) {
          console.log(error)
          toast.clear()
          Toast.setDefaultOptions({ duration: 3000 });
          Toast('取消操作')
          return
      }
      Toast('操作成功')
      this.show = false
      this.getShopList()
    },
    async buy(){
      const toast=Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
      });
      try {
          let res = await buySellerTicket(this.address,
                                  this.tokenId,
                                  ''+decimalToBalance(this.shellPrice));
          console.log(`buy: ${res}`)
          toast.clear()
          this.$message({
              type: 'info',
              message: '购买成功'
            });  
      } catch (error) {
          console.log(error)
          toast.clear()
          Toast.setDefaultOptions({ duration: 3000 });
          Toast('取消操作')
          return
      }
      this.dialogMis()
      this.getShopList();
    },
    dialogMis(){
      this.show = false;
    },
    showDetail(item){
      // console.log(item.imgUrl)
      this.show = true;
      this.showImage = item.imgUrl; 
      this.shellPrice = item.price;
      this.seller = item.seller;
      this.tokenId = item.tokenId
    }
  }
}
</script>

<style scoped>
.van-s{
  padding: 0px;
  background-color: rgba(240, 248, 255, 0);
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.dialog-price{
  color: #f0cc72;
  font-size: 20px;
  font-weight: bolder;
}
.shop {
  padding: 30px 20px 0;
}
.card {
  display: flex;
  align-items: center;
  height: 210px;
  padding: 30px 35px;
  margin-bottom: 20px;
  background-repeat: round;
  background-image: url(../assets/images/shop-bg.png);
  background-size: cover;
}
.card .right-box {
  flex: 1;
  position: relative;
  margin-left: 16px;
  font-size: 13px;
}
.card .right-box .name {
  color: #f0cc72;
  font-size: 16px;
  font-weight: bolder;
}
.card .right-box .desc {
  display: inline-block;
  padding: 2px;
  margin-top: 6px;
  margin-bottom: 12px;
  border-radius: 2px;
  background-color: #f0cc72;
  color: #e31b1e;
}
.card .right-box .desc2 {
  display: inline-block;
  padding: 2px;
  margin-top: 6px;
  margin-left:10px;
  margin-bottom: 12px;
  border-radius: 2px;
  background-color: #0974ff;
  color: #fff;
}
.card .right-box .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f6b40e;
}
.card .right-box .bottom .num {
  font-weight: bold;
  font-size: 18px;
}

.dialog-div{
  margin-top: 60%;
  text-align:center;
  /* display:table-cell; */
  vertical-align:middle; 
}
</style>
