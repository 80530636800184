<template>
  <div class="games">
    <van-swipe :autoplay="3000" class="swiper">
      <van-swipe-item >
        <img src="../assets/images/bac2.png" class="banner-img" />
      </van-swipe-item>
      <van-swipe-item >
        <img src="../assets/images/bac3.png" class="banner-img" />
      </van-swipe-item>
      <van-swipe-item >
        <img src="../assets/images/bac4.png" class="banner-img" />
      </van-swipe-item>
    </van-swipe>
    <div class="notice-div2">
      <span style="font-size:14px">可兑换: {{rewardCount}}</span>
      <span style="margin-left:5px">预计收益:{{personalReward}} cfx（20:00可领取）</span>
    </div>
    <div class="notice-div">
      <p>请到「我的」「我的奖励」点击卡片领取收益</p>
    </div>
    <div class="gustext">
      <span>总剩余: {{totalCount}}</span>
      <span style="margin-left:20px">今日剩余: {{dayRestCount}} （每天12:00更新）</span>
    </div>
    <input class="w" v-model="gnum" type="text" placeholder="-" name="textfield" id="textfield" />
    <div class="gusnum">已猜次数: {{aCount}}</div>
    <div class="gusnum">猜数字（{{minNum}}~{{maxNum}}），答对送NFT</div>
    <div style="margin: 16px">
      <van-button round block type="info" @click="exchangeWithWord">提交</van-button>
    </div>
    <div class="van-dialog3" v-show="nftGetShow">
      <van-image width="300" lazy-load :src="imgUrl" @click="nftGetShow=false">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
  </div>
</template>

<script>
import {getBaseInfo,balance,
  exchangeWithWord,lastExchangeTime,getIsReset,
  initCfxMinerContract} from "../util/indexCfxMiner.js"
import {getCardPicUrl} from "../util/getLevel.js"
import { Toast } from 'vant'
export default {
  name: 'Games',
  async created() {
    this.address = localStorage.getItem('MyAccount');
    await initCfxMinerContract()
    this.initBaseInfo();
  },
  data() {
    return {
      rewardCount:"",
      personalReward:"",
      imgUrl:"",
      nftGetShow:false,
      minNum:0,
      maxNum:0,
      aCount:0,
      totalCount:0,
      dayRestCount:0,
      gnum: '',
      address: '',
      showKeyboard: true,
    }
  },
  methods: {
    async initBaseInfo(){
      let rewardCount = await balance();
      this.rewardCount = ''+rewardCount
      this.personalReward= Number(2021/Number(this.rewardCount)).toFixed(4);
      let res = await getBaseInfo(this.address);
      let arr = JSON.parse(JSON.stringify(res));
      this.minNum=''+arr[0];
      this.maxNum=''+arr[1];
      this.dayRestCount=''+arr[2];
      this.totalCount=''+arr[3];
      this.aCount=''+arr[4];
      console.log('==count=>'+this.aCount)
      if(Number(this.aCount)>=Number(5)){
        let resTime = await lastExchangeTime();
        //有木有跟新
        // let lastTime = resTime-86400 > 0?resTime - 86400:0;
        let lastTime = 0;
        // console.log('lasttime: '+lastTime)
        console.log('===>'+resTime)
        //0, 1612756887 ,1612843340,xx,xx
        let islastReset0 = await getIsReset(this.address,0);//第1天更新
        let islastReset1 = await getIsReset(this.address,1612756887);//2
        let islastReset2 = await getIsReset(this.address,1612843340);//3
        let islastReset3 = await getIsReset(this.address,1612929639);//4
        console.log(islastReset0)
        console.log(islastReset1)
        console.log(islastReset2)
        console.log(islastReset3)
        let islastReset = islastReset0||islastReset1||islastReset2||islastReset3;
        let isReset = await getIsReset(this.address,resTime);
        console.log('=islastReset==>'+islastReset)
        console.log('=isRest==>'+isReset)
        if(lastTime!=resTime){
          //时间不同跨度不同，需要重置
          //没重置则为5 今日未重置，昨日已重置的
          if(islastReset){
            let nowTime = new Date().getTime()/1000
            if(nowTime > Number(resTime)){
              if(!isReset){
                this.aCount='0';
              }
            }
          }
        }
      }
    },
    async exchangeWithWord(){
      if(Number(this.gnum)<Number(this.minNum) || Number(this.gnum)>Number(this.maxNum)){
        Toast("今天的数字区间是（"+this.minNum+"~"+this.maxNum+"）")
        return
      }
      if(this.dayRestCount<=0){
        Toast("今天已经发放完啦！每天中午 12:00 更新")
        return
      }
      if(this.aCount>=5){
        Toast("今天猜的次数已达上限啦！")
        return
      }
      if(this.gnum==0){
        Toast("请填写数字")
        return
      }
      const toast=Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
      });
      try{
        let res = await exchangeWithWord(this.address,this.gnum);
        // 000000000000000000000000186dbddd61814ad28665afce757ae97ccf077a9b msg.sender
        // 00000000000000000000000000000000000000000000000000000002540be406 gas
        // 0000000000000000000000000000000000000000000000000000000000000000 level

        // 00000000000000000000000011310bdff1826049e9e47d64888e6765b26356cb
        // 00000000000000000000000000000000000000000000000000000000000001f0
        // 0000000000000000000000000000000000000000000000000000000000000004
        console.log(`exchangeWithWord: ${JSON.stringify(res)}`)
        let rep = JSON.parse(JSON.stringify(res));
        let logs = rep.logs;
        console.log('logs: '+logs)
        let data = logs[logs.length-1].data;
        console.log('data: '+data)
        // let data ='0x00000000000000000000000011310bdff1826049e9e47d64888e6765b26356cb00000000000000000000000000000000000000000000000000000000000001f00000000000000000000000000000000000000000000000000000000000000004';
        let level = data.substring(2).substring(128,192);
        console.log('=level=>' + level)
        toast.clear()
        if(level=='0000000000000000000000000000000000000000000000000000000000000000'){
          let gus = data.substring(2).substring(64,128);
          console.log('=gus=>' + gus)
          Toast.setDefaultOptions({ duration: 5000 });
          Toast('猜错了，正确答案是:'+(parseInt(gus,16)-10000000000))
        }else{
          Toast('恭喜获得字母卡,请前往"我的"查看')
          console.log('===>'+Number(level))
          let pic = getCardPicUrl(Number(level));
          console.log(pic)
          this.imgUrl = pic
          this.nftGetShow = true;
        }

      }catch(error){
        console.log('-----------')
        console.log(error)
        toast.clear()
        console.log((''+error.message).indexOf('WalletMiddleware'))
        if((''+error.message).indexOf('WalletMiddleware')!= -1){
          //todo
          Toast("点击地址重连钱包")
        }
      }
      this.initBaseInfo()
    },
  }
}
</script>

<style scoped>
.games {
  padding-top: 30px;
  text-align: center;
}
.gustext-btn{
  height: 30px;
  margin-right: 15px;
}
.gustext{
  display: flex;
  align-items: center; 
  text-align: center;
  justify-content: center; 
  color: #f6e1a8;
}
.gusnum {
  color: #f6e1a8;
}
.van-button--info {
  color: #63021a;
  background-color: #f6e1a8;
  border: 1px solid #f6e1a8;
}
.w {
  width: 200px;
  height: 200px;
  font-size: 50px;
  color: #f6e1a8;
  border: 1px solid #ffffff00;
  background-image: url(../assets/images/guessnum.png);
  background-repeat: round;
  background-color: #ffffff00;
  text-align: center;
}
.create-button {
  float: left;
  font-size: 20px;
  margin-left: 50px;
}

.swiper {
  margin-left: 5%;
  margin-right: auto;
  width: 90%;
  height: 170px;
  margin-bottom: 10px;
}
.swiper .banner-img {
  display: block;
  width: 100%;
  height: 100%;
}
.notice-div2{
    text-shadow:0 0 0.2em rgb(215, 200, 0),
                -0 -0 0.2em rgb(30, 145, 0);
}
.notice-div2 span{
    text-align: center;
    padding: 2px;
    margin: 0;
    font-family: helvetica,arial,sans-serif;
    color: #d1d1d1;
    font-size: 16px;
    font-weight: bold;
}
.notice-div{
    text-shadow:0 0 0.2em #f87,
                -0 -0 0.2em #f87;
}
.notice-div p{
    text-align: center;
    padding: 2px;
    margin: 0;
    font-family: helvetica,arial,sans-serif;
    color: #d1d1d1;
    font-size: 16px;
    font-weight: bold;
}
</style>